import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../../providers/session";
import { getCurrencySymbol } from "../../../../functions/priceFormat";
import Head from "next/head";
import theme from '../../../theme';

const useStyles = makeStyles({
	content: {
		...theme.cmsContent,
		color: theme.palette.primary.color,
		'& p, div:not(.title) > & > div, li': {
			fontSize: 14,
			color: theme.palette.primary.color,
			lineHeight: 1.7
		},
		'&:not(.isVariant) p, &:not(.isVariant) li': {
			marginTop: 10,
			marginBottom: 20,
			color: theme.palette.primary.color,
			lineHeight: 1.4
		},
		'&:not(.isVariant) li': {
			marginBottom: 12,
		},
		[theme.breakpoints.down('sm')]: {
			...theme.cmsContentMobile
		},
		'.stores-text & p, .stores-text & div:not(.title), .stores-text & li, .stores-text & a,&.newStyling p, &.newStyling div:not(.title), &.newStyling li, &.newStyling a': {
			fontSize: 12,
			color: theme.palette.primary.color,
			fontWeight: 300,
			[theme.breakpoints.up('sm')]: {
				fontSize: 14
			}
		},
		'& .slim-life-preheading': {
			textAlign: 'center',
			letterSpacing: '0.125em',
			marginTop: 20,
			[theme.breakpoints.down('sm')]: {
				marginTop: 15,
			},
		},
		'& .slim-life-heading': {
			marginBottom: 20,
			textAlign: 'center',
			color: '#459ED7',
			fontFamily: 'Red Bright, serif',
			fontSize: 80,
			fontWeight: 400,
			[theme.breakpoints.down('sm')]: {
				fontSize: '55px !important',
				marginBottom: 10
			},
		},
		'& .slim-life-heading.christmas': {
			color: '#730a06'
		},
		'& .slim-life-teaser': {
			textAlign: 'center',
			fontSize: 18,
			color: theme.palette.primary.lowlight,
			lineHeight: 'normal',
			padding: '0 10px',
			fontWeight: 300,
			marginBottom: 10,
			[theme.breakpoints.down('sm')]: {
				marginBottom: 30,
				fontSize: 16,
				padding: '0 15px'
			},
		},
		'&.left .slim-life-teaser': {
			textAlign: 'left'
		},
		'& .slim-life-text': {
			marginBottom: 30,
			fontSize: 18,
			fontWeight: 300,
			color: theme.palette.primary.lowlight,
			lineHeight: 'normal',
			[theme.breakpoints.down('sm')]: {
				fontSize: 16,
				marginBottom: 20,
				padding: '0 15px'
			},
		},
		'&.caption': {
			fontSize: 14,
			lineHeight: 1.7,
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		'.gw-section & p, .gw-section & h1': {
			color: `${theme.palette.primary.background} !important`
		},
		'.gw-section & .btn, .gw-section & .btn:hover': {
			backgroundColor: `${theme.palette.primary.background} !important`,
			color: `${theme.palette.primary.color} !important`
		},
		'&.line-through': {
			'&:after': {
				content: '""',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				height: 1,
				backgroundColor: theme.palette.primary.color,
				transform: 'translateY(16px)'
			},
			'& p strong, & h2 strong': {
				position: 'relative',
				zIndex: 1,
				display: 'inline-block',
				marginBottom: 0,
				padding: '0px 20px',
				fontWeight: 400,
				backgroundColor: theme.palette.primary.background,
			}
		},
		'& ul.two-columns': {
			listStyle: 'none',
			columnCount: 2
		},
		'&.align-left': {
			marginLeft: 0
		}
	}
}, {name: 'TextContent'});


export default function TextContent({content, block, className, isVariant = false, newStyling = false, style}) {
	const router = useRouter();
	const { config } = useContext(SessionContext);
	const classes = useStyles();
	const [loadedEmbed, setLoadedElement] = useState(false);

	useEffect(() => {
		if(content?.indexOf('instagram-media') > 0) {
			let embedElement = document.getElementById('instagramEmbed');

			if(!embedElement) {
				setLoadedElement(true);
			}

			window?.instgrm?.Embeds?.process();
		}

	}, []);

	function getStyle() {
		const maxWidth = !!className && className?.includes('wider') ? 835 : 720
		return { ...style, maxWidth }
	}

	function isMappedField() {
		if(block) {
			let slot = block.slots[0];
			return slot.config.content.source === 'mapped';
		}

		return false;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function injectCurrencySymbol(content) {
		let currencySymbol = getCurrencySymbol();

		if(!content) {
			return content;
		}

		return content?.replaceAll('|currencySymbol|', currencySymbol);
	}

	function handleMyNewportLinks(content) {
		if(content && config.myNewport) {
			return content?.replaceAll('%7CmyNewport%7C', config.myNewport);
		}

		return content;
	}

	return (
		typeof content ? <Container
			className={[classes.content, className, isVariant ? 'isVariant' : '', newStyling ? 'newStyling' : ''].join(' ')}
			style={getStyle() || {}} disableGutters>
			{isMappedField() ?
				<h1 dangerouslySetInnerHTML={{ __html: urlNormalize(content) }}></h1> :
				<div dangerouslySetInnerHTML={{ __html: urlNormalize(handleMyNewportLinks(injectCurrencySymbol(content))) }} onClick={(e) => {
					let link = e.target;
					if (e.target.tagName !== "A") {
						let closestLink = e.target.closest('a');

						if (closestLink && closestLink.tagName !== "A") {
							link = false;
						} else {
							link = closestLink;
						}
					}

					if (link) {
						e.nativeEvent.preventDefault();
						e.preventDefault();
						e.stopPropagation();
						e.nativeEvent.stopPropagation();

						if(link.target === '_blank') {
							window.open(link);
						} else {
							router.push(link.href);
						}
					}
				}}/>
			}

			<style dangerouslySetInnerHTML={{ __html: '.instagram-media, .instagram-media-registered {display: none; margin: 0 auto !important;}' }}></style>
			{loadedEmbed ? <Head><script type="text/javascript" src="//www.instagram.com/embed.js"></script></Head> : ''}
		</Container> : ''
	);
}
